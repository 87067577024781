export const LANDING_PATH = '/';

// User Account & Auth Routes
export const AUTH_PATH = 'auth';
export const DASHBOARD_PATH = 'dashboard';
export const LOGIN_BASE_LINK_PATH = 'login';
export const REGISTER_BASE_LINK_PATH = 'register';
// export const USER_LISTING = `user-management`;
export const ADMIN_USER_LISTING = `admin-users`;
export const APP_USER_LISTING = `app-users`;
export const APP_INTERIOR_DESIGNER = `interior-designer`;
export const APP_HOME_OWNER = `homeowner-users`;
export const APP_SUB_CATEGORY = `subcategories`;
export const BRANDS = `brands`;
export const TAGS = `tags`;
export const STYLE = `style`;
export const BASE_MATERIAL = `base-material`;
export const FINISH = `finish`;
export const TAGS_STYLE = `${STYLE}-tags`;
export const TAGS_BASE_MATERIAL = `${BASE_MATERIAL}-tags`;
export const TAGS_FINISH = `${FINISH}-tags`;

export const SALES = `sales`;
export const ITEMS = `items`;
export const PROFILE = `profile`;
export const ORDERS = `${SALES}/orders`;
export const RETURNS = `${SALES}/returns`;
export const PAYMENTS_METHODS = `${SALES}/payment-methods`;
export const DELIVERY_METHODS = `${SALES}/delivery-methods`;
export const CATALOG = `catalog`;
export const PRODUCTS = `${CATALOG}/products`;
export const CATEGORIES = `${CATALOG}/categories`;
export const PAGES = `Pages`;
export const SYSTEM_SETTINGS = `system-settings`;
export const STORES = `stores`;
export const CATEGORIES_NEW = `categories`;
export const TASK_AND_PROJECTS = `task-and-projects`;
export const JOBS = `jobs`;
export const TRANSACTIONS = `transactions`;
export const BLOGS = `blogs`;
export const BLOG_TAGS = 'blog-tags';
export const ARTICLES = 'articles';
export const ARTICLES_NEW = 'Add Articles';
export const ARTICLES_EDIT = 'Edit Articles';



export const RESET_PASSWORD_BASE_LINK_PATH = 'reset-password';
export const FORGOT_PASSWORD_BASE_LINK_PATH = 'forgot-password';
export const FORGOT_PASSWORD_OTP_CONFIRMATION = 'forgot-password-otp-confirmation';
export const LOGIN_PATH = `/${AUTH_PATH}/${LOGIN_BASE_LINK_PATH}`;
export const REGISTER_PATH = `${AUTH_PATH}/${REGISTER_BASE_LINK_PATH}`;
export const RESET_PASSWORD_PATH = `/${AUTH_PATH}/${RESET_PASSWORD_BASE_LINK_PATH}`;
export const FORGOT_PASSWORD_PATH = `${AUTH_PATH}/${FORGOT_PASSWORD_BASE_LINK_PATH}`;


export const ADD_PATH = 'add';
export const EDIT_PATH = ':id';
export const VIEW_PATH = 'view';
